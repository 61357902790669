<div fxLayout="column" fxLayoutAlign="start start" *ngIf="control">
  <mat-hint [ngClass]="
      control.hasError('required') || control.hasError('minlength') || control.hasError('maxlength')
        ? 'invalid'
        : 'valid'
    ">
    <mat-icon>{{
      control.hasError('required') || control.hasError('minlength') || control.hasError('maxlength')
      ? 'close'
      : 'check'
      }}</mat-icon>{{ passwordHintErrors.minLenthError }}</mat-hint>
  <mat-hint [ngClass]="
      control.hasError('required') || control.hasError('hasNumber')
        ? 'invalid'
        : 'valid'
    ">
    <mat-icon>{{
      control.hasError('required') || control.hasError('hasNumber')
      ? 'close'
      : 'check'
      }}</mat-icon>{{ passwordHintErrors.hasNumberError }}</mat-hint>
  <mat-hint [ngClass]="
      control.hasError('required') || control.hasError('hasCapitalCase')
        ? 'invalid'
        : 'valid'
    ">
    <mat-icon>{{
      control.hasError('required') || control.hasError('hasCapitalCase')
      ? 'close'
      : 'check'
      }}</mat-icon>{{ passwordHintErrors.hasCapitalCaseError }}</mat-hint>
  <mat-hint [ngClass]="
      control.hasError('required') || control.hasError('hasSmallCase')
        ? 'invalid'
        : 'valid'
    ">
    <mat-icon>{{
      control.hasError('required') || control.hasError('hasSmallCase')
      ? 'close'
      : 'check'
      }}</mat-icon>{{ passwordHintErrors.hasLowerCaseError }}</mat-hint>
  <mat-hint [ngClass]="
      control.hasError('required') || control.hasError('hasSpecialCharacters')
        ? 'invalid'
        : 'valid'
    ">
    <mat-icon>{{
      control.hasError('required') || control.hasError('hasSpecialCharacters')
      ? 'close'
      : 'check'
      }}</mat-icon>{{ passwordHintErrors.hasSpecialCharacters }}</mat-hint>
</div>